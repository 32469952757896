import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import React from "react";
import { Link as RSLink } from "react-scroll";
import styled from "styled-components";
import { Button } from "./Button";
import { ICatalogProduct } from "./CatalogProducts";
import { IconInfo16, IconRedirect14 } from "./icons-ts";
import { InputNumber } from "./InputNumber";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Media, mediaQuery } from "./lib/media";
import { Link } from "./Link";
import { Tag } from "./Tag";
import { Tipped } from "./Tipped";
import { TargetBlankHelper } from "./TargetBlankHelper";

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Title = styled.div`
  color: ${colors.darkDk};
  letter-spacing: -1.5px;
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("35px")}

  ${mediaQuery("tablet")`
    ${fontSize("25px")}
  `}
`;

const Tags = styled.div`
  margin-left: 20px;

  & div:first-child {
    margin-right: 5px;
  }
`;

const Packaging = styled.div`
  color: ${colors.dark};
  letter-spacing: -1.5px;
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("19px")}
  letter-spacing: -0.5px;

  ${mediaQuery("tablet")`
    ${fontSize("16px")}
  `}
`;

const Intro = styled.div`
  line-height: 1.5rem;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const ExternalLink = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

const FooterContainer = styled.div<{ images: { src: string }[] | null }>`
  background: ${colors.lightLter};
  border-radius: 10px;
  padding: 20px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  ${mediaQuery("tablet")`
    margin-top: 40px;
    flex-wrap: wrap;
  `}

  ${mediaQuery("phablet")`
    margin-left: -15px;
    margin-right: -15px;
  `}

  margin-top: ${({ images }) => (images && images.length > 0 ? "40px" : "0")};
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const PointsPromo = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("19px")}
  color: ${colors.light};
  letter-spacing: -0.5px;
  text-decoration: line-through;
  margin-right: 10px;
`;

const Tooltip = styled.div`
  margin-left: 10px;
`;

const Points = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("25px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
`;

const SubmitContainer = styled.div`
  display: flex;
`;

const ButtonSubmitMobile = styled(Media)`
  flex-basis: 100%;
  margin-top: 30px;
`;

const Submit = styled.div`
  margin-left: 20px;
`;

const PurchaseQuantitySelector = ({
  tippedContent,
  product,
  value,
  onChange,
  onSubmit,
}: {
  tippedContent?: string;
  product: ICatalogProduct;
  value: number;
  onChange: (value: number) => void;
  onSubmit?: () => void;
}) => (
  <>
    <FooterContainer images={product.images || null}>
      <PointsContainer>
        {product.promo ? (
          <>
            <PointsPromo>
              {pointsToCouronnes(product.price)} couronnes
            </PointsPromo>
            <Points>{pointsToCouronnes(product.promoPrice)} couronnes</Points>
          </>
        ) : (
          <Points>{pointsToCouronnes(product.price)} couronnes</Points>
        )}
        {tippedContent && (
          <Tooltip>
            <Tipped content={tippedContent}>
              <IconInfo16 fill={colors.light} width={16} height={16} />
            </Tipped>
          </Tooltip>
        )}
      </PointsContainer>
      <SubmitContainer>
        <InputNumber
          min={1}
          max={999}
          step={1}
          value={value}
          onChange={onChange}
        />
        <Media when={">768"}>
          <Submit>
            <Button type="submit" onClick={onSubmit}>
              Commander
            </Button>
          </Submit>
        </Media>
      </SubmitContainer>
      <ButtonSubmitMobile when={"<=768"}>
        <Button type="submit" fullwidth onClick={onSubmit}>
          Commander
        </Button>
      </ButtonSubmitMobile>
    </FooterContainer>
  </>
);

export interface ICatalogDetailSheetIntroProps {
  tippedContent?: string;
  product: ICatalogProduct;
  quantityInputProps: {
    value: number;
    onChange: (value: number) => void;
  };
}

const Desc = styled.div`
  white-space: pre-line;
`;

export const CatalogDetailSheetIntro = ({
  product,
  tippedContent,
  quantityInputProps,
}: ICatalogDetailSheetIntroProps) => (
  <>
    <div>
      <Header>
        <Title>{product.label}</Title>
        {(product.promo || product.newProduct) && (
          <Tags>
            {product.promo && <Tag theme="success">promo</Tag>}
            {product.newProduct && <Tag theme="warning">nouveau</Tag>}
          </Tags>
        )}
      </Header>
      {product.subLabel && <Packaging>{product.subLabel}</Packaging>}
      <Intro>
        <Desc>{product.shortDescription}</Desc>
        {product.infoUrl && (
          <ExternalLink>
            <RSLink
              activeClass="active"
              to="tabs-container"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <Link dark href={"#"}>
                En savoir plus
              </Link>
            </RSLink>
          </ExternalLink>
        )}
      </Intro>
      {product.infoUrl && (
        <Link
          icon={<IconRedirect14 aria-hidden="true" width={14} />}
          href={product.infoUrl}
          target="_blank"
          rel="noopener"
          aria-label={`Plus d'informations sur ${product.label}`}
        >
          Plus d'informations
          <TargetBlankHelper />
        </Link>
      )}
    </div>
    <PurchaseQuantitySelector
      tippedContent={tippedContent}
      {...quantityInputProps}
      product={product}
    />
  </>
);
