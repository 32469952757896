import React from "react";
import styled from "styled-components";

const Container = styled.h1`
  margin: 0;
  font-size: inherit;
`;

export const CustomH1 = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <Container className={className && className}>{children}</Container>;
};
