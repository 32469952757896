import React from "react";
import { slide as Menu, Styles as MenuStyles } from "react-burger-menu";
import styled from "styled-components";
import { IconArrowR10, IconPadlock16, IconUser16 } from "./icons-ts";
import { ILayoutItemNavLink } from "./Layout";
import { LinkPartner } from "./LayoutNavigation";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { LogicalLink } from "./LogicalLink";
import { TargetBlankHelper } from "./TargetBlankHelper";

const bmStyles = (loginAs?: boolean): MenuStyles => ({
  bmBurgerBars: {},
  bmBurgerButton: {},
  bmCross: {},
  bmCrossButton: {},
  bmItemList: {
    display: "flex",
    flexDirection: "column",
  },
  bmMenu: {
    background: "#fff",
  },
  bmMenuWrap: {
    bottom: "0",
    height: "calc(100% - 66px)",
  },
  bmMorphShape: {},
  bmOverlay: {
    background: "rgba(255, 255, 255, 0.8)",
    bottom: "0",
    height: "calc(100% - 66px)",
  },
});

const Nav = styled.div`
  flex: 1;
`;

const Link = styled(LogicalLink)`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  border-bottom: 1px solid ${colors.lightLt};
  color: ${colors.darkDk};
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  position: relative;
  text-decoration: none;
  transition: color .2s;
`;

const Footer = styled.div`
  background: ${colors.lightLter};
  display: flex;
  justify-content: space-between;
  padding: 18px 15px;
`;

const PartnerName = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  align-items: center;
  color: ${colors.primary};
  display: flex;

  ${IconUser16} {
    margin-right: 15px;
  }
`;

export interface ILayoutMenuMobileProps {
  isOpen: boolean;
  navLinks: ILayoutItemNavLink[];
  onPartnerChangeClick: () => void;
  onLogoutClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  partnerName: string;
  loginAs?: boolean;
}

export const LayoutMenuMobile = ({
  isOpen,
  navLinks,
  onPartnerChangeClick,
  onLogoutClick,
  partnerName,
  loginAs,
}: ILayoutMenuMobileProps) => {
  return (
    <Menu
      customCrossIcon={false}
      customBurgerIcon={false}
      width={"100%"}
      styles={bmStyles(loginAs)}
      isOpen={isOpen}
    >
      <Nav>
        {navLinks.map((item, i) => (
          <Link to={item.to} key={i} target={item.target}>
            {item.label}
            <IconArrowR10 height={10} fill={colors.darkDk} />
            {item.target === "_blank" && <TargetBlankHelper />}
          </Link>
        ))}
      </Nav>
      <div>
        <Footer>
          <PartnerName>
            <IconUser16 width={16} fill={colors.primary} />
            {partnerName}
            <LinkPartner onClick={onPartnerChangeClick}>changer</LinkPartner>
          </PartnerName>
          <LogicalLink to="/" onClick={onLogoutClick}>
            <IconPadlock16 fill={colors.darkDk} width={16} />
          </LogicalLink>
        </Footer>
      </div>
    </Menu>
  );
};
