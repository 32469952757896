import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { IconRoundedCross50 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  justify-content: center;
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("25px")}
  color: ${colors.primaryDk};
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Desc = styled.div`
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 1.5rem;
`;

export const ModalOrderNoPoint = ({ onClick }: { onClick: () => void }) => (
  <>
    <Icon>
      <IconRoundedCross50 width={50} height={50} fill={colors.primaryDk} />
    </Icon>
    <Title>Vous n'avez pas assez de couronnes !</Title>
    <Desc>
      Désolé, vous ne pouvez pas commander ce produit votre solde de couronnes
      étant insuffisant pour l'instant.
    </Desc>
    <Container>
      <Button onClick={onClick}>J'ai compris</Button>
    </Container>
  </>
);
