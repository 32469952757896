import React from "react";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { mediaQuery } from "./lib/media";

export const HtmlContent = styled(({ className, html, children }) =>
  html ? (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <div className={className}>{children}</div>
  ),
)`
  p {
    line-height: 1.563rem;
  }

  h1 {
    margin-bottom: 60px;

    ${mediaQuery("tablet")`
      margin-bottom: 30px;
    `}
  }

  h1:first-child {
    margin-top: 0;
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 15px;

    ${mediaQuery("tablet")`
      margin-top: 20px;
    `}
  }

  ul,
  ol {
    li:not(:first-child) {
      margin-top: 10px;
    }
  }

  ul {
    padding-left: 18px;
  }

  ol {
    padding-left: 0px;
    margin: 0;
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;
  }

  ol li {
    counter-increment: my-awesome-counter;
    position: relative;
  }

  ol li::before {
    content: counter(my-awesome-counter);
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    border-bottom: 1px dotted ${colors.primary};
    color: ${colors.primary};
    transition: color 0.2s, border-color 0.2s;

    &:hover {
      color: ${colors.primaryDk};
      border-color: ${colors.primaryDk};
    }
  }
`;
