import React from "react";
import { setLogicalLink } from "@royalcanin-fr-loyalty/ui-kit";
import { Link } from "gatsby";
import { TargetBlankHelper } from "@royalcanin-fr-loyalty/ui-kit/src/TargetBlankHelper";

const LogicalLink = React.forwardRef(
  (
    { to, href, target, secondary, active, dark, icon, white, ...props },
    ref,
  ) => {
    if (
      to &&
      (isExternal(to) || isAnchor(to) || isMailTo(to) || isTargetBlank(target))
    ) {
      href = to;
      to = null;
    }

    return to ? (
      <Link to={to} {...props} ref={ref} />
    ) : href ? (
      <a href={href} target={target} {...props} ref={ref}>
        {props.children}
        {target === "_blank" && <TargetBlankHelper />}
      </a>
    ) : (
      <span {...props} ref={ref} />
    );
  },
);

function isExternal(str) {
  return str && str.match(/^https?:\/\//i);
}
function isAnchor(str) {
  return str && str.match(/^#/i);
}
function isTargetBlank(target) {
  return target === "_blank";
}
function isMailTo(str) {
  return str && str.match(/^mailto.*@.*/i);
}

export const setupLogicalLink = () => {
  setLogicalLink(LogicalLink);
};
