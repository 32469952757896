import React, { useRef, useState } from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import { IconArrowL16, IconArrowR16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { Media, mediaQuery } from "./lib/media";

const CarouselNavContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const CarouselNavThumb = styled.div<{ active?: boolean }>`
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colors.light};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 75px;
  justify-content: center;
  margin-top: 30px;
  padding: 10px;
  transition: border-color 0.2s;
  width: 75px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:hover {
    border-color: ${colors.primary};
  }

  ${(p) =>
    p.active &&
    css`
      border-color: ${colors.primary};
    `}
`;

const CarouselNavThumbImage = styled.div<{ bg?: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-image: url(${(p) => p.bg});
`;

const CarouselNav = ({
  goTo,
  images,
  activeSlide,
}: {
  goTo: (i: number) => void;
  images: string[];
  activeSlide: number;
}) => (
  <CarouselNavContainer>
    {images.map((item, i) => (
      <CarouselNavThumb
        active={i === activeSlide}
        key={i}
        onClick={() => {
          goTo(i);
        }}
      >
        <CarouselNavThumbImage bg={item} />
      </CarouselNavThumb>
    ))}
  </CarouselNavContainer>
);

const SliderContainer = styled.div`
  position: relative;

  ${mediaQuery("tablet")`
    margin-bottom: 40px;
  `}
`;

const gradientStyles = css`
  content: "";
  position: absolute;
  top: 0;
  bottom: 110px;
  width: 30px;
  z-index: 8;
  height: 100%;

  ${mediaQuery("tablet")`
    bottom: 0;
  `}
`;

const Container = styled.div`
  position: relative;

  &::before {
    ${gradientStyles}
    left: 0;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }

  &::after {
    ${gradientStyles}
    right: -30px;
    background-image: linear-gradient(
      -270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
`;

const ImageSlider = styled.img`
  margin: 0 auto;
  outline: none;
  height: 240px;
  max-width: 100%;
  height: auto;
`;

const Arrows = styled.div`
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  z-index: 9;

  ${mediaQuery("desktop")`
    top: calc(50% - 52px);
  `}

  ${mediaQuery("tablet")`
    top: 50%;
  `}

  ${IconArrowL16},
  ${IconArrowR16} {
    cursor: pointer;
    transition: fill 0.2s;

    &:hover {
      fill: ${colors.primary};
    }
  }
`;

export const CatalogDetailSheetCarousel = ({
  images,
  desc,
}: {
  images: string[];
  desc?: string;
}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderEl = useRef<Slider>(null);

  const goTo = (slideNumber: number) => {
    if (sliderEl.current) {
      sliderEl.current.slickGoTo(slideNumber);
    }
  };

  const next = () => {
    if (sliderEl.current) {
      sliderEl.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderEl.current) {
      sliderEl.current.slickPrev();
    }
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <Container>
      <SliderContainer>
        {images.length > 1 && (
          <Arrows>
            <IconArrowL16
              fill={colors.darkDk}
              onClick={() => previous()}
              height={16}
            />
            <IconArrowR16
              fill={colors.darkDk}
              onClick={() => next()}
              height={16}
            />
          </Arrows>
        )}
        <Slider
          ref={sliderEl}
          {...settings}
          beforeChange={(oldIndex, newIndex) => {
            setActiveSlide(newIndex);
          }}
        >
          {images.map((item, i) => (
            <div key={i}>
              <ImageSlider src={item} alt={desc} />
            </div>
          ))}
        </Slider>
        {images.length > 1 && (
          <Media when={">768"}>
            <CarouselNav
              images={images}
              goTo={goTo}
              activeSlide={activeSlide}
            />
          </Media>
        )}
      </SliderContainer>
    </Container>
  );
};
