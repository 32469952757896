import { WindowLocation } from "@reach/router";
import {
  Breadcrumb,
  CatalogDetailSheet,
  CatalogDetailSheetPlaceholder,
  CatalogDetailTabs,
  Form,
  ICatalogProduct,
  IItemBreadcrumb,
  useInputProps,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import { Media } from "@royalcanin-fr-loyalty/ui-kit/src/lib/media";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { CatalogProductPurchaseModal } from "../components/CatalogProductPurchaseModal";
import { Layout } from "../components/Layout";
import { useProduct } from "../lib/hooks/useProduct";
import { useLocationContext } from "../lib/LocationProvider";
import { getProductPromoTippedText } from "../lib/productUtils";
import { isWebpSupported } from "../lib/utils";
import { withPage } from "../lib/withPage";
import Error404 from "./404";
import Error500 from "./500";

const getBreadcrumbItems = (product?: ICatalogProduct | null) => {
  const items: IItemBreadcrumb[] = [
    {
      label: "Catalogue",
      link: "/catalog",
    },
  ];
  if (product) {
    items.push({
      label: product.label,
    });
  }
  return items;
};

const useMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted;
};

const CatalogDetail = ({ location }: { location: WindowLocation<any> }) => {
  const {
    data: product,
    loading: productLoading,
    error: errorProducts,
  } = useProduct(useLocationContext().query.id);
  const [isModalOrderOpen, setIsModalOrderOpen] = useState(
    !!(location.state && location.state.isModalOrderOpen),
  );
  const defaultQuantity = (location.state && location.state.quantity) || 1;
  const mounted = useMounted();

  if (errorProducts) {
    return <Error500 error={errorProducts} location={location} />;
  }
  if (mounted && !productLoading && !product) {
    return <Error404 key="404" location={location} />;
  }

  return (
    <>
      <Helmet>
        <title>{product?.label || "Catalogue"}</title>
        <meta
          name="description"
          content={product?.shortDescription || "Produit Royal Canin ©"}
        />
      </Helmet>
      <Layout>
        <Wrapper>
          <Media when={">768"}>
            <Breadcrumb breadcrumb={getBreadcrumbItems(product)} />
          </Media>

          {productLoading || !product ? (
            <CatalogDetailSheetPlaceholder />
          ) : (
            <Form
              initialValues={{
                quantity: defaultQuantity,
              }}
              onSubmit={() => {
                setIsModalOrderOpen(true);
              }}
              render={({ handleSubmit, values: { quantity } }) => (
                <>
                  <CatalogProductPurchaseModal
                    quantity={quantity}
                    product={product}
                    open={isModalOrderOpen}
                    onClose={() => setIsModalOrderOpen(false)}
                  />
                  <form onSubmit={handleSubmit}>
                    <CatalogDetailSheet
                      product={{
                        ...product,
                        images: product.images?.map((image) => ({
                          src: isWebpSupported()
                            ? image.detail.webp
                            : image.detail.src,
                        })),
                      }}
                      catalogDetailSheetIntroProps={{
                        tippedContent: getProductPromoTippedText(product),
                        product,
                        quantityInputProps: useInputProps({
                          name: "quantity",
                        }),
                      }}
                    />
                    <CatalogDetailTabs product={product} />
                  </form>
                </>
              )}
            />
          )}
        </Wrapper>
      </Layout>
    </>
  );
};

export default withPage(CatalogDetail);
