import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from "@material-ui/pickers";
import { format } from "date-fns";
import React, { useState } from "react";
import { IconCalendar16 } from "./icons-ts";
import { IInputProps, Input } from "./Input";
import { colors } from "./lib/constants";

const TextFieldComponent = ({ variant, onChange, ...props }: IInputProps) => (
  <Input icon={<IconCalendar16 width={16} fill={colors.dark} />} {...props} />
);

export const DatePicker = ({
  children,
  onChange,
  ...props
}: {
  children?: React.ReactNode;
  onChange: (dadte: string) => void;
} & DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MUIDatePicker
      onClick={() => setIsOpen(true)}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      fullWidth
      onChange={date => onChange(format(date as Date, "yyyy-MM-dd"))}
      openTo="year"
      TextFieldComponent={TextFieldComponent as any}
      label={children}
      format="dd/MM/yyyy"
      {...props}
    />
  );
};
