import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import React from "react";
import styled from "styled-components";
import { IconRoundedCheck50 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("25px")}
  color: ${colors.success};
  letter-spacing: -0.5px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Desc = styled.div`
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 1.5rem;
`;

const PointsBox = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  background: ${colors.lightLt};
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-top: 30px;
`;

const Points = styled.span`
  ${fontFamily(ffdinPro.bold)}
`;

export const ModalOrderSuccess = ({ points }: { points: number }) => (
  <>
    <Icon>
      <IconRoundedCheck50 width={50} height={50} fill={colors.success} />
    </Icon>
    <Title>Commande enregistrée</Title>
    <Desc>
      Votre commande a été enregistrée et sera traitée dans les plus brefs
      délais. Un email de confirmation vous a été envoyé
    </Desc>
    <PointsBox>
      Votre nouveau solde de couronnes : <Points>{pointsToCouronnes(points)} couronnes</Points>
    </PointsBox>
  </>
);
