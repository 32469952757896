import {
  Alert,
  Button,
  CatalogProducts,
  CatalogProductsPlaceholder,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import { NetworkStatus } from "apollo-client";
import React, { useState } from "react";
import posed from "react-pose";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { useFetchProduct } from "../lib/hooks/useProduct";
import {
  useDynamicFilterProducts,
  useProducts,
} from "../lib/hooks/useProducts";
import { navigateTo } from "../lib/LocationProvider";
import { isWebpSupported } from "../lib/utils";
import { CatalogFilters, useDefaultFilters } from "./CatalogFilters";
import { CatalogFiltersMobile } from "./CatalogFiltersMobile";

const Container = styled.div`
  text-align: center;
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const AnimButton = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

export const Catalog = ({
  hasScroll,
  filters: givenFilters,
  isBottom,
}: {
  hasScroll: boolean;
  filters: any;
  isBottom: boolean;
}) => {
  const [catalogFiltersMobileIsOpen, setCatalogFiltersMobileIsOpen] = useState(
    false,
  );
  const initialValues = useDefaultFilters();
  const filters = {
    ...initialValues,
    ...givenFilters,
  };
  filters.promo =
    typeof filters.promo === "string"
      ? filters.promo === "true"
        ? true
        : filters.promo === "false"
        ? false
        : filters.promo
      : filters.promo;
  filters.newProduct =
    typeof filters.newProduct === "string"
      ? filters.newProduct === "true"
        ? true
        : filters.newProduct === "false"
        ? false
        : filters.newProduct
      : filters.newProduct;

  const {
    data: products,
    pageInfo,
    onLoadMore,
    loading: productsLoading,
    networkStatus,
  } = useProducts(filters, { limit: 18 });
  const { dynamicFilterProducts } = useDynamicFilterProducts();

  const fetchProduct = useFetchProduct();

  const loading =
    typeof window === "undefined"
      ? true
      : productsLoading && products.length === 0;
  const showNoItems = !loading && products.length === 0;
  const showPlaceholder =
    loading || networkStatus === NetworkStatus.setVariables;
  const loadMoreLoading = productsLoading;

  return (
    <>
      <Wrapper>
        <Row>
          <Col xs={false} sm={false} md={3} lg={3}>
            <CatalogFilters
              dynamicFilters={dynamicFilterProducts || {}}
              values={filters}
              onSubmit={(values) => {
                navigateTo(`/catalog`, values);
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={9} lg={9}>
            {showNoItems && (
              <Alert type="warning">
                Aucun produit ne correspond aux filtres séléctionnés. Veuillez
                élargir vos paramètres de recherche.
              </Alert>
            )}
            {showPlaceholder ? (
              <CatalogProductsPlaceholder
                cardsLength={products.length > 0 ? products.length : 9}
              />
            ) : (
              <CatalogProducts
                onSelect={(id) => {
                  navigateTo("/catalog-detail", { id });
                }}
                onMouseOver={(id) => {
                  fetchProduct(id);
                }}
                onPurchase={(id) => {
                  navigateTo(
                    "/catalog-detail",
                    { id },
                    { state: { quantity: 1, isModalOrderOpen: true } },
                  );
                }}
                products={products.map((product) => ({
                  ...product,
                  images: product.images?.map((image) => ({
                    src: isWebpSupported()
                      ? image.listing.webp
                      : image.listing.src,
                  })),
                }))}
              />
            )}
            {pageInfo.hasNextPage && (
              <Container>
                <Button disabled={loadMoreLoading} onClick={onLoadMore}>
                  Voir plus de produits
                </Button>
              </Container>
            )}
          </Col>
        </Row>
      </Wrapper>
      <AnimButton pose={!isBottom || !hasScroll ? "on" : "off"}>
        <CatalogFiltersMobile
          dynamicFilters={dynamicFilterProducts || {}}
          hasScroll={hasScroll}
          onSubmit={(values) => {
            navigateTo("/catalog", values);
          }}
          catalogFiltersMobileIsOpen={catalogFiltersMobileIsOpen}
          setCatalogFiltersMobileIsOpen={setCatalogFiltersMobileIsOpen}
        />
      </AnimButton>
    </>
  );
};
