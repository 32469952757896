import { addMonths, endOfDay, format, isFuture, isPast } from 'date-fns';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import posed, { PoseGroup } from 'react-pose';

import { useUserToken } from '@igloo-be-omnipartners/hooks';
import {
  AdWarning, ButtonMore, ILitterItem, LittersList, LittersListItemDefaultTransformer,
  LittersListMobile, Loader, Media, Wrapper
} from '@royalcanin-fr-loyalty/ui-kit';

import { Layout } from '../../components/Layout';
import { useLitter, useLitterImport, useLitters } from '../../lib/hooks/useLitters';
import { useUserRole } from '../../lib/hooks/useUserRole';
import { Litters_litters_result } from '../../lib/hooks/__generated__/Litters';
import { linkTo } from '../../lib/LocationProvider';
import { withPage } from '../../lib/withPage';

const AdWarningContainer = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: "spring", stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: -50,
    opacity: 0,
    transition: { duration: 150 },
  },
});

const getLitterAdStatus = (publicationDateEnd?: Date) =>
  !publicationDateEnd
    ? ""
    : isFuture(new Date(publicationDateEnd))
    ? "published"
    : "archived";

const formatDate = (date?: string) => {
  return (date && format(new Date(date), "dd.MM.yyyy")) || "";
};

export const isMaxPublicationDatePast = (dob: string) => {
  return isPast(
    addMonths(
      endOfDay(new Date(dob)),
      parseInt(process.env.GATSBY_LITTER_MAX_PUBLICATION_DATE_END || "4", 10),
    ),
  );
};

const formatLitter = (litter: Litters_litters_result): ILitterItem => ({
  id: litter.id,
  isMaxPublicationDatePast: isMaxPublicationDatePast(litter.dob),
  pet: {
    breed: litter.breed && litter.breed.name,
    number: litter.totalCount,
    dob: formatDate(litter.dob),
  },
  kit:
    litter.lastPurchase && litter.lastPurchase.status !== "canceled"
      ? {
          status:
            (litter.lastPurchase.shippingAddress.isImmediateDelivery &&
              litter.lastPurchase.status) ||
            "",
          quantity: litter.lastPurchase.quantity || 0,
          label: litter.lastPurchase.productData.name,
        }
      : undefined,
  ad:
    litter && litter.publicationDateEnd
      ? {
          status: getLitterAdStatus(litter.publicationDateEnd),
          date: formatDate(litter.publicationDateEnd),
          link: `/litters/ad/${litter.id}`,
          url: litter.permalink,
          shouldUpdate: !!litter.shouldUpdateWiki,
        }
      : undefined,
});

const ItemTransformerComponent = ({
  item,
  children,
}: React.ComponentProps<typeof LittersListItemDefaultTransformer>) => {
  const { litter } = useLitter(item.id, {
    skip: !item.ad?.shouldUpdate,
    pollInterval: 5000,
  });

  if (litter) {
    return children({ item: formatLitter(litter) });
  }

  return children({ item });
};

const Litters = () => {
  const { data, pageInfo, loading, onLoadMore } = useLitters({});
  const { data: role } = useUserRole();

  const litters = data.map(formatLitter);

  const { loading: littersImportLoading, littersImport } = useLitterImport();
  const token = useUserToken();

  useEffect(() => {
    if (token) {
      littersImport();
    }
  }, [token]);

  const allLittersLoading =
    typeof window === "undefined" || loading || littersImportLoading;

  const getCompleteLink: (item: ILitterItem) => string | undefined = (item) => {
    if (
      (item.kit && item.ad) ||
      (item.kit && !item.ad && item.isMaxPublicationDatePast)
    ) {
      return undefined;
    }

    if (!item.kit && !item.ad && item.isMaxPublicationDatePast) {
      return linkTo(`/litters/kits`, {
        litter: item.id,
      });
    }

    if (!item.kit && !item.ad) {
      return linkTo(`/litters/choice`, {
        litter: item.id,
      });
    }

    return linkTo(`/litters/${!item.kit ? "kits" : "ad"}`, {
      litter: item.id,
    });
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Gérez vos portées déclarées sur le 'Programme Ambassadeur' de Royal Canin. Publiez des annonces sur le mini-site Royal Canin Adoption et commandez gratuitement des colis chiot/chaton dédiés aux éleveurs."
        />
      </Helmet>
      <Layout>
        <Wrapper>
          <PoseGroup>
            {littersImportLoading && (
              <AdWarningContainer key="import">
                <AdWarning type="warning">
                  Import des portées depuis Royal Start en cours...
                </AdWarning>
              </AdWarningContainer>
            )}
          </PoseGroup>
          <Media when={">768"}>
            <LittersList
              litters={litters}
              newLitterButtonLink={
                !!role && role.isAuthorizedToCreateLitter
                  ? "/litters/create"
                  : undefined
              }
              loading={allLittersLoading}
              getCompleteLink={getCompleteLink}
              adsLinks={(item) => ({
                archived: linkTo(`/litters/ad`, {
                  litter: item.id,
                }),
                published: linkTo(`/litters/ad`, {
                  litter: item.id,
                }),
              })}
              ItemTransformerComponent={ItemTransformerComponent}
            />
          </Media>
          <Media when={"<=768"}>
            <LittersListMobile
              litters={litters}
              newLitterButtonLink={
                !!role && role.isAuthorizedToCreateLitter
                  ? "/litters/create"
                  : undefined
              }
              loading={allLittersLoading}
              getCompleteLink={getCompleteLink}
              adsLinks={(item) => ({
                archived: linkTo(`/litters/ad`, {
                  litter: item.id,
                }),
                published: linkTo(`/litters/ad`, {
                  litter: item.id,
                }),
              })}
              ItemTransformerComponent={ItemTransformerComponent}
            />
          </Media>
          {loading && !litters.length && <Loader />}
          {pageInfo.hasNextPage && (
            <ButtonMore
              disabled={loading}
              onClick={() => {
                onLoadMore();
              }}
            />
          )}
        </Wrapper>
      </Layout>
    </>
  );
};

export default withPage(Litters, {
  title: "Portées",
});
