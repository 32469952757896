import { format } from "date-fns";
import React from "react";
import styled, { css } from "styled-components";
import { IconRoundedCheck16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div`
  display: flex;
  margin-bottom: 60px;
`;

const AdSuccessTitle = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  align-items: center;
  color: ${colors.success};
  display: flex;
  justify-content: center;
  letter-spacing: -0.5px;
  margin-bottom: 15px;

  > svg {
    display: block;
    margin-right: 5px;
  }
`;

const Item = styled.div<{ kits?: boolean; ad?: { publicationDateEnd: Date } }>`
  text-align: center;
  flex: 1;

  ${p =>
    p.kits &&
    p.ad &&
    css`
      padding: 15px 0;
    `}
`;

const AdSuccessDesc = styled.div`
  letter-spacing: -0.5px;
  line-height: 1.5rem;
`;

const AdSuccessNote = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 5px;
`;

const Separator = styled.div`
  background: ${colors.lightLt};
  margin: 0 30px;
  width: 1px;
`;

export const LittersSuccess = ({
  kits,
  ad,
}: {
  kits?: boolean;
  ad?: {
    publicationDateEnd: Date;
  };
}) => (
  <Container>
    {kits && (
      <Item kits={kits} ad={ad}>
        <AdSuccessTitle>
          <IconRoundedCheck16 fill={colors.success} width={16} />
          Commander des kits
        </AdSuccessTitle>
        <AdSuccessDesc>
          Votre commande de colis a bien été enregistrée.
        </AdSuccessDesc>
      </Item>
    )}
    {kits && ad && <Separator />}
    {ad && (
      <Item kits={kits} ad={ad}>
        <AdSuccessTitle>
          <IconRoundedCheck16 fill={colors.success} width={16} />
          Publier une annonce
        </AdSuccessTitle>
        <AdSuccessDesc>
          Votre annonce a bien été enregistrée et sera publiée jusqu’au{" "}
          {format(new Date(ad.publicationDateEnd), "dd.MM.yyyy")}.
        </AdSuccessDesc>
        <AdSuccessNote>
          Un délai de maximum 60 minutes peut être observé pour la publication
          de votre annonce.
        </AdSuccessNote>
      </Item>
    )}
  </Container>
);
