import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { IconMinus10, IconPlus10 } from "./icons-ts";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const Container = styled.div`
  display: inline-flex;
  height: 40px;
  position: relative;
`;

const Input = styled.input`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("16px")}
  border-radius: 20px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  margin: 0;
  padding-left: 10px;
  padding: 0;
  text-align: center;
  width: 115px;
  appearance: none;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonInput = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const CustomButton = styled(Button)`
  height: 30px;
  width: 30px;
`;

const CustomButtonInputRight = styled(ButtonInput)`
  right: 5px;
`;

const CustomButtonInputLeft = styled(ButtonInput)`
  left: 5px;
`;

export const InputNumber = ({
  min,
  max,
  step,
  value,
  onChange,
}: {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}) => {
  const dispatchChange = (v: number) =>
    onChange(Math.max(Math.min(v, max), min));

  return (
    <Container>
      <CustomButtonInputLeft>
        <CustomButton
          icon={<IconMinus10 width={10} fill="#fff" />}
          disabled={value <= min}
          onClick={() => {
            dispatchChange(value <= min ? value : value - 1);
          }}
        />
      </CustomButtonInputLeft>
      <Input
        value={value}
        type="number"
        min={min}
        pattern="[0-9]+"
        step={step}
        onChange={(e) => dispatchChange(parseInt(e.target.value, 10))}
      />
      <CustomButtonInputRight>
        <CustomButton
          icon={<IconPlus10 width={10} fill="#fff" />}
          onClick={() => dispatchChange(value + 1)}
        />
      </CustomButtonInputRight>
    </Container>
  );
};
