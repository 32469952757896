import { format } from "date-fns";
import React, { useState } from "react";
import posed from "react-pose";
import styled from "styled-components";

import { pointsToCouronnes, shouldShowPoints } from "@royalcanin-fr-loyalty/common";

import { Alert } from "./Alert";
import { ButtonMore } from "./ButtonMore";
import { CustomH1 } from "./CustomH1";
import { IconRoundedMinus30, IconRoundedPlus30 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Loader } from "./Loader";
import { OrdersDetailProduct } from "./OrdersDetailProduct";
import { PageTitle } from "./PageTitle";
import { Tag } from "./Tag";

import type { CatalogPurchaseType } from "../../../__generated__/globalTypes";
import { IShippingDetailProps } from "./ShippingDetail";

const OrderContainer = styled.div`
  &:not(:first-child) {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid ${colors.lightLt};

    ${mediaQuery("phablet")`
      padding-top: 20px;
      margin-top: 20px;
    `}
  }
`;

export interface ICatalogPurchase {
  id: string;
  date: Date;
  partner: {
    name: string;
  };
  shippingAddress: IShippingDetailProps;
  productData: {
    name?: string | null;
    reference?: string | null;
    subLabel?: string | null;
    image?: string | null;
  };
  price: number;
  initialPrice: number;
  quantity: number;
  status: string;
  type: CatalogPurchaseType;
}

const Icon = posed.div({
  off: {
    opacity: 0,
  },
  on: {
    opacity: 1,
  },
});

const Row = styled.div`
  cursor: pointer;
`;

const Detail = posed.div({
  off: {
    height: 0,
    opacity: 0,
    overflow: "hidden",
  },
  on: {
    height: "auto",
    opacity: 1,
  },
});

const Header = styled.div`
  display: flex;
  position: relative;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;

  ${mediaQuery("phablet")`
    margin-left: 15px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("25px")}
  letter-spacing: -0.5px;
  color: ${colors.darkDk};
  flex: 1;
  display: flex;
  align-items: center;

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
  `}
`;

const Highlight = styled.span`
  ${fontFamily(ffdinPro.condensedBold)}
`;

const Status = styled.span`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

const Total = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("25px")}
  margin-left: auto;
  color: ${colors.primary};
  letter-spacing: -0.5px;

  ${mediaQuery("phablet")`
    ${fontSize("19px")}
    flex-basis: 100%;
    margin-top: 5px;
  `}
`;

const ButtonMoreContainer = styled.div`
  margin-top: 40px;
`;

const Points = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-top: 5px;
`;

const CustomIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  display: inline-block;
`;

const CustomPlusIcon = styled(Icon)`
  display: inline-block;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
`;

const CustomIconRoundedMinus30 = styled(IconRoundedMinus30)`
  display: block;
`;

const CustomIconRoundedPlus30 = styled(IconRoundedPlus30)`
  display: block;
`;

const TotalContainer = styled.div`
  text-align: right;
`;

export const OrdersDetail = ({
  orders,
  onMoreClick,
  pageInfo,
  moreLoading,
  loading,
}: {
  orders: ICatalogPurchase[];
  onMoreClick: () => void;
  pageInfo: {
    page: number;
    hasNextPage: boolean;
  };
  moreLoading: boolean;
  loading: boolean;
}) => {
  const [isOpen, setisOpen] = useState<{ [key: string]: boolean }>({});

  return (
    <>
      <CustomH1>
        <PageTitle>Mes commandes fidélité</PageTitle>
      </CustomH1>
      {loading && <Loader />}
      {!loading && orders.length === 0 ? (
        <Alert type="warning">Pas de commandes trouvées.</Alert>
      ) : (
        orders.map((item, i) => (
          <OrderContainer key={i}>
            <Row onClick={() => setisOpen({ ...isOpen, [i]: !isOpen[i] })}>
              <Header>
                <div>
                  <CustomIcon pose={isOpen[i] ? "on" : "off"}>
                    <CustomIconRoundedMinus30
                      fill={colors.dark}
                      width={30}
                      height={30}
                    />
                  </CustomIcon>
                  <CustomPlusIcon pose={isOpen[i] ? "off" : "on"}>
                    <CustomIconRoundedPlus30
                      fill={colors.dark}
                      width={30}
                      height={30}
                    />
                  </CustomPlusIcon>
                </div>
                <TitleContainer>
                  <Title>
                    {format(new Date(item.date), "dd.MM.yyyy")} - Commande
                    n&deg; <Highlight>{item.id}</Highlight>
                    {(item.shippingAddress.isImmediateDelivery ||
                      item.status === "canceled") && (
                      <Status>
                        {item.status === "pending" ||
                        item.status === "reserved" ? (
                          <Tag theme="warning" size="xs">
                            En cours
                          </Tag>
                        ) : item.status === "canceled" ? (
                          <Tag theme="danger" size="xs">
                            Annulé
                          </Tag>
                        ) : (
                          <Tag theme="success" size="xs">
                            Traité
                          </Tag>
                        )}
                      </Status>
                    )}
                  </Title>
                  <TotalContainer>
                    <Total>
                      {item.quantity > 0 && item.price > 0 ? (
                        <>
                          {pointsToCouronnes(item.price * item.quantity)}{" "}
                          couronnes
                          {shouldShowPoints(new Date(item.date)) && (
                            <Points>{item.price * item.quantity} points</Points>
                          )}
                        </>
                      ) : (
                        "Offert"
                      )}
                    </Total>
                  </TotalContainer>
                </TitleContainer>
              </Header>
            </Row>
            <Detail pose={isOpen[i] ? "on" : "off"}>
              <OrdersDetailProduct item={item} />
            </Detail>
          </OrderContainer>
        ))
      )}

      {pageInfo.hasNextPage && (
        <ButtonMoreContainer>
          <ButtonMore onClick={onMoreClick} disabled={moreLoading} />
        </ButtonMoreContainer>
      )}
    </>
  );
};
