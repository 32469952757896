import fastDeepEqual from "fast-deep-equal";
import { debounce, get, set } from "lodash";
import React, { Fragment, ReactNode, useCallback, useContext } from "react";
import {
  AnyObject,
  FieldInputProps,
  Form as FinalForm,
  FormProps,
  FormRenderProps,
  FormSpy,
  useField,
  UseFieldConfig,
  useFormState,
} from "react-final-form";
import { Schema, ValidationError } from "yup";

type IFieldLabels<T = AnyObject> = {
  [k in keyof T]?: JSX.Element | string;
};
type IValidationErrors<T = AnyObject> = {
  [k in keyof T]?: JSX.Element | string;
};

const formatValidateMessage = (
  index: number,
  path: string,
  message: string,
  fieldLabels?: IFieldLabels,
) => (
  <Fragment key={index}>
    {message.split(path).map((v, i) => (
      <Fragment key={i}>
        {i !== 0 && ((fieldLabels && fieldLabels[path]) || path)}
        {v}
      </Fragment>
    ))}
  </Fragment>
);

async function validateSchema<T>(
  values: T,
  schema: Schema<T>,
  fieldLabels?: IFieldLabels,
) {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return (err as ValidationError).inner.reduce(
      (errors: any, { path, message }: any, i: number) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, [
            ...get(errors, path),
            formatValidateMessage(i, path, message, fieldLabels),
          ]);
        } else {
          set(errors, path, [
            formatValidateMessage(i, path, message, fieldLabels),
          ]);
        }
        return errors;
      },
      {},
    );
  }

  return {};
}

const FormRender = ({ render }: { render: () => ReactNode }) => <>{render()}</>;

const CustomFormContext = React.createContext<{
  loading?: boolean;
  errors?: IValidationErrors;
  fieldLabels?: IFieldLabels;
}>({
  loading: undefined,
  errors: {},
});

export function Form<FormValues extends AnyObject = AnyObject>({
  render,
  loading,
  schema,
  fieldLabels,
  errors,
  ...props
}: Omit<FormProps<FormValues>, "render"> & {
  render: (
    p: FormRenderProps<FormValues> & {
      useInputProps: (p: IUseInputProps<keyof FormValues>) => IUseInputResult;
    },
  ) => ReactNode;
  loading?: boolean;
  schema?: Schema<Partial<FormValues>>;
  fieldLabels?: IFieldLabels<FormValues>;
  errors?: IValidationErrors<FormValues>;
}) {
  const validate = useCallback(
    (values: FormValues) => {
      if (props.validate) {
        return props.validate(values);
      } else if (schema) {
        return validateSchema(values, schema, fieldLabels);
      } else {
        return;
      }
    },
    [schema],
  );

  return (
    <CustomFormContext.Provider value={{ loading, fieldLabels, errors }}>
      <FinalForm<FormValues>
        {...props}
        validate={validate}
        render={(p) => (
          <FormRender render={() => render({ ...p, useInputProps } as any)} />
        )}
        initialValuesEqual={fastDeepEqual}
      />
    </CustomFormContext.Provider>
  );
}

interface IUseInputProps<T> {
  name: T;
  config?: UseFieldConfig<any>;
  onChange?: () => void;
  disabled?: boolean;
}

export interface IUseInputResult extends FieldInputProps<any, HTMLElement> {
  disabled?: boolean;
  error: boolean;
  helperText?: string;
  label: JSX.Element | string;
}

export const useInputProps = <T extends string = string>({
  name,
  config,
  onChange,
  disabled,
}: IUseInputProps<T>): IUseInputResult => {
  const field = useField(name, {
    ...config,
  });
  const customForm = useContext(CustomFormContext);

  const error =
    (customForm.errors && customForm.errors[name]) ||
    (field.meta.touched && field.meta.error) ||
    (!field.meta.dirtySinceLastSubmit && field.meta.submitError);

  const loading = customForm.loading || field.meta.submitting;

  return {
    ...field.input,
    onChange: (e: any) => {
      field.input.onChange(e);
      if (onChange) {
        onChange();
      }
    },
    disabled: loading || disabled,
    error: !!error,
    helperText: error,
    label: (customForm.fieldLabels && customForm.fieldLabels[name]) || "",
  };
};

export const FormAutoSave = ({ onSubmit }: { onSubmit: () => void }) => {
  const debouncedOnSubmit = useCallback(debounce(onSubmit, 400), [onSubmit]);
  return (
    <FormSpy
      onChange={(formState) => {
        if (!formState.dirty) {
          return;
        }
        debouncedOnSubmit();
      }}
    />
  );
};

export const useButtonProps = (disabled?: boolean) => ({
  disabled: useFormState().submitting || disabled,
});
