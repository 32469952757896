import { pointsToCouronnes } from "@royalcanin-fr-loyalty/common";
import React from "react";
import styled from "styled-components";
import { useHover } from "use-events";
import { Button } from "./Button";
import { ICatalogProduct } from "./CatalogProducts";
import { IconBasket16 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { Media, mediaQuery } from "./lib/media";
import { Tag } from "./Tag";

const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(51, 51, 51, 0.1);
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  ${mediaQuery("desktop")`
    padding-bottom: 100%;
    height: 0;
  `}

  ${mediaQuery("tablet")`
    width: 110px;
    height: 110px;
  `}
`;

const Title = styled.div<{ isHovered?: boolean }>`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("16px")}
  color: ${colors.darkDk};
  letter-spacing: -0.5px;
  text-decoration: none;
  transition: color 0.2s;
  cursor: pointer;
  color: ${(p) => (p.isHovered ? colors.primary : colors.darkDk)};

  &:hover {
    color: ${colors.primary};
  }
`;

const CardImage = styled.div`
  align-items: center;
  bottom: 0;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.03);

    + ${Title} {
      color: ${colors.primary};
    }
  }

  ${mediaQuery("phablet")`
    padding: 20px;
  `}
`;

const Image = styled.div<{ $image?: string }>`
  height: 100%;
  width: 100%;
  ${(p) =>
    p.$image ? `background: url(${p.$image}) no-repeat center/contain` : ""};
`;

const Container = styled.div`
  display: flex;

  ${mediaQuery("desktop")`
    flex-direction: column;
    flex: 1;
  `}
`;

const Tags = styled.div`
  left: 20px;
  position: absolute;
  top: 20px;
  z-index: 2;

  & div:first-child {
    margin-right: 5px;
  }
`;

const Triangle = styled.div`
  border-color: ${colors.success} transparent transparent transparent;
  border-style: solid;
  border-width: 30px 30px 0 0;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  ${mediaQuery("desktop")`
    padding: 20px 20px 0 20px;
  `}

  ${mediaQuery("tablet")`
    padding: 8px 8px 8px 15px;
  `}
`;

const Desc = styled.div`
  ${fontFamily(ffdinPro.regular)}
  ${fontSize("14px")}
  color: ${colors.dark};
  margin-bottom: 10px;
  margin-top: 5px;
`;

const Footer = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("19px")}
  align-items: center;
  display: flex;
  justify-content: space-between;
  letter-spacing: -0.5px;
  margin-top: auto;
`;

const PointsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const PointsPromo = styled.div`
  ${fontFamily(ffdinPro.condensedMedium)}
  ${fontSize("16px")}
  color: ${colors.light};
  letter-spacing: -0.5px;
  text-decoration: line-through;
  margin-right: 10px;
`;

const TagContainer = styled.div`
  margin-left: 10px;
  display: inline;
`;

export const CatalogProductsCard = ({
  item,
  onSelect,
  onMouseOver,
  onPurchase,
}: {
  onSelect: (id: string) => void;
  onMouseOver: (id: string) => void;
  onPurchase: (id: string) => void;
  item: ICatalogProduct;
}) => {
  const [isHovered, bind] = useHover();
  return (
    <Container>
      <Card
        {...bind}
        onClick={() => onSelect(item.id)}
        onMouseOver={() => onMouseOver(item.id)}
      >
        {(item.promo || item.newProduct) && (
          <>
            <Media when={">768"}>
              <Tags>
                {item.promo && <Tag theme="success">Promo</Tag>}
                {item.newProduct && <Tag theme="warning">Nouveau</Tag>}
              </Tags>
            </Media>
            {item.promo && (
              <Media when={"<=768"}>
                <>
                  <Triangle />
                </>
              </Media>
            )}
          </>
        )}
        <CardImage>
          <Image $image={item.images?.[0]?.src} />
        </CardImage>
      </Card>
      <Content>
        <Title onClick={() => onSelect(item.id)} isHovered={isHovered}>
          {item.label}
          <Media when={"<=768"}>
            {item.newProduct && (
              <TagContainer>
                <Tag theme="warning" size="xs">
                  Nouveau
                </Tag>
              </TagContainer>
            )}
          </Media>
        </Title>
        {item.subLabel && <Desc>{item.subLabel}</Desc>}
        <Footer>
          <PointsContainer>
            {item.promo ? (
              <>
                <PointsPromo>
                  {pointsToCouronnes(item.price)} couronnes
                </PointsPromo>
                <div>{pointsToCouronnes(item.promoPrice)} couronnes</div>
              </>
            ) : (
              <div>{pointsToCouronnes(item.price)} couronnes</div>
            )}
          </PointsContainer>
          <Button
            onClick={() => onPurchase(item.id)}
            icon={<IconBasket16 fill="white" height="16px" />}
          />
        </Footer>
      </Content>
    </Container>
  );
};
