import React from 'react';
import styled from 'styled-components';

import { ILayoutItemFooterLink } from './Layout';
import { colors } from './lib/constants';
import { ffdinPro, fontFamily, fontSize } from './lib/fonts';
import { LogicalLink } from './LogicalLink';
import { Wrapper } from './Wrapper';

const Container = styled.div`
  background: ${colors.darkDk};
`;

const FooterLinkContainer = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  flex-shrink: 0; /*  Fix safari mobile */
  align-items: center;
`;

const footerTextStyles = `
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("11px")}
  color: #fff;
`;

const FooterLink = styled(LogicalLink)`
  ${footerTextStyles}
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("11px")}
  text-decoration: none;
  transition: color 0.2s;

  &:not(:first-child) {
    margin-left: 15px;
  }

  &:hover {
    color: ${colors.dark};
  }
`;

const Copyright = styled(LogicalLink)`
  ${footerTextStyles}
  margin-top: 15px;
  text-align: center;
`;

export interface ILayoutFooterProps {
  footerLinks: ILayoutItemFooterLink[];
  locale: string;
}

export const LayoutFooter = ({ footerLinks, locale }: ILayoutFooterProps) => (
  <Container>
    <Wrapper>
      <Row>
        <FooterLinkContainer>
          {footerLinks
            .sort((a, b) => {
              return a.priority - b.priority;
            })
            .map((item, i) => (
              <FooterLink
                href={item.url[locale]}
                target={item.target}
                rel="noopener"
                aria-label={`En savoir plus sur ${item.label[locale]}`}
                key={i}
              >
                {item.label[locale]}
              </FooterLink>
            ))}
        </FooterLinkContainer>
        <Copyright>
          ©{new Date().getFullYear()} Royal Canin SAS. Tous droits réservés.
          <br />
          Une filiale de Mars, Incorporated.
        </Copyright>
      </Row>
    </Wrapper>
  </Container>
);
