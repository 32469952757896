import { format } from "date-fns/esm";
import { fr } from "date-fns/locale";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Loader } from "./Loader";
import { Wrapper } from "./Wrapper";

export const Petsales = ({
  total,
  currentYear,
  currentMonth,
  loading,
}: {
  total?: number;
  currentYear?: number;
  currentMonth?: number;
  loading?: boolean;
}) => (
  <Container>
    <Wrapper>
      <Row center="xs">
        <Col xs={12} sm={12} md={10} lg={10}>
          <Box>
            <Title>Vos ventes de chiots/chatons</Title>
            {!!loading ? (
              <Loader />
            ) : (
              <Items>
                <Item highlight title="Total des ventes" count={total || 0} />
                <Item
                  title={`Ventes en ${format(new Date(), "MMMM", {
                    locale: fr,
                  })}`}
                  count={currentMonth || 0}
                />
                <Item
                  title={`Ventes en ${format(new Date(), "yyyy")}`}
                  count={currentYear || 0}
                />
              </Items>
            )}
          </Box>
        </Col>
      </Row>
    </Wrapper>
  </Container>
);

const Item = ({
  title,
  count,
  highlight,
}: {
  title: string;
  count: number;
  highlight?: boolean;
}) => (
  <ItemContainer>
    <ItemTitle $highlight={highlight}>{title}</ItemTitle>
    <ItemCount $highlight={highlight}>{count}</ItemCount>
  </ItemContainer>
);

const ItemCount = styled.div<{ $highlight?: boolean }>`
  color: ${(props) => (props.$highlight ? colors.primary : colors.dark)};
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("45px")}
  letter-spacing: -0.5px;
  line-height: 3.375rem;
  text-align: right;
  text-shadow: 2px 2px 0 rgba(51, 51, 51, 0.1);
  text-transform: uppercase;

  ${mediaQuery("phablet")`
    text-align: left;
  `}
`;

const ItemTitle = styled.div<{ $highlight?: boolean }>`
  color: ${(props) => (props.$highlight ? colors.primary : colors.dark)};
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("14px")}
  line-height: 1.063rem;
  margin-right: 45%;
  text-transform: uppercase;

  ${mediaQuery("tablet")`
    margin-right: 47%;
  `}

  ${mediaQuery("phablet")`
    margin-right: 0;
    margin-bottom: 10px;
  `}

  ${mediaQuery("mobile")`
    margin-bottom: 0;
  `}
`;

const Container = styled.div`
  margin-top: 100px;

  ${mediaQuery("phablet")`
    margin-top: 50px;
  `}
`;

const Box = styled.div`
  background: ${colors.lightLter};
  border-radius: 10px;
  padding: 30px;

  ${mediaQuery("mobile")`
    padding: 15px;
  `}
`;

const Title = styled.div`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
  line-height: 1.188rem;
  margin-bottom: 30px;
`;

const Items = styled.div`
  display: flex;

  ${mediaQuery("mobile")`
    flex-direction: column;
  `}
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: calc(100% / 3);

  ${mediaQuery("phablet")`
    display: block;
  `}

  ${mediaQuery("mobile")`
    width: 100%;
    display: flex;
  `}

  &:not(:first-child) {
    border-left: 1px solid ${colors.lightLt};
    margin-left: 30px;
    padding-left: 30px;

    ${mediaQuery("mobile")`
      border-left: 0;
      margin-left: 0;
      margin-top: 10px;
      padding-left: 0;
    `}
  }
`;
