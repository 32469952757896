import {
  pointsToCouronnes,
  shouldShowPoints,
} from "@royalcanin-fr-loyalty/common";
import { format } from "date-fns";
import { groupBy } from "lodash";
import React, { Fragment } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { ButtonMore, IButtonMoreProps } from "./ButtonMore";
import { IconMinus10, IconPlus10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";
import { mediaQuery } from "./lib/media";
import { Link } from "./Link";
import { Wrapper } from "./Wrapper";

export interface IPointsHistory {
  action: string;
  transaction_date: Date;
  label: string;
  orderNr?: number;
  total_points: string;
}

const RowYear = styled.div`
  &:not(:first-child) {
    margin-top: 60px;
  }
`;

const Container = styled.div`
  ${mediaQuery("tablet")`
     margin-top: 40px;
  `}

  ${mediaQuery("desktop")`
     margin-top: 60px;
  `}
`;

const DateYear = styled.div`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("25px")}
  color: ${colors.primary};
  letter-spacing: -0.5px;
  position: relative;

  &::after {
    background: ${colors.lightLt};
    content: "";
    height: 1px;
    margin-top: -0.5px;
    position: absolute;
    right: 0;
    top: 50%;
    width: calc(100% - 90px);
  }
`;

const Month = styled.div`
  margin-top: 40px;
  position: relative;

  ${mediaQuery("desktop")`
    padding-left: 90px;
  `}
`;

const Order = styled.div`
  margin-top: 30px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0;
  }

  tr:not(:first-child) td {
    padding-top: 20px;
  }
`;

const Text = styled.span`
  ${fontFamily(ffdinPro.medium)}
`;

const DescDesktop = styled.td<{ width?: string }>`
  ${fontFamily(ffdinPro.medium)}
  display: table-cell;

  ${mediaQuery("mobile")`
    display: none;
  `}
`;

const DescMobile = styled.tr<{ colspan?: number }>`
  display: none;

  ${mediaQuery("mobile")`
    display: table-row;
    ${fontSize("14px")}
  `}
`;

const LinkOrder = styled(Link)`
  margin-left: 5px;
`;

const Desc = ({
  label,
  orderNr,
}: Pick<IPointsHistory, "label" | "orderNr">) => (
  <Text>
    {label}
    {orderNr && (
      <LinkOrder dark href="/">
        {orderNr}
      </LinkOrder>
    )}
  </Text>
);

const Caps = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors.lightLt};
  display: inline-flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Crowns = styled.div<{ $count: boolean }>`
  width: 110px;
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("16px")}
  letter-spacing: -0.5px;
  margin-left: 10px;
  color: ${(p) => (p.$count ? colors.success : colors.primaryDk)};
  text-align: right;
`;

const Points = styled.div`
  ${fontFamily(ffdinPro.medium)}
  ${fontSize("12px")}
  color: ${colors.dark};
  text-align: right;
`;

const CustomTd = styled.td`
  width: 25px;
`;

const CapsContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PointsHistory = ({
  points,
  buttonMoreProps,
}: {
  points: IPointsHistory[];
  buttonMoreProps: IButtonMoreProps;
}) => {
  const historyPointsGrouped = groupBy(points, (item) =>
    item.transaction_date.getFullYear(),
  );
  const years = Object.keys(historyPointsGrouped)
    .sort()
    .reverse()
    .map<[string, typeof points]>((year) => [year, historyPointsGrouped[year]]);

  return (
    <Container>
      <Wrapper>
        <Row center="sm">
          <Col xs={12} sm={12} md={12} lg={10}>
            {years.map(([year, items], i) => (
              <RowYear key={i}>
                <DateYear>{year}</DateYear>
                <Month key={`${i}`}>
                  {/* <DateMonth>{month}</DateMonth> */}
                  <Order>
                    <Table>
                      <tbody>
                        {items.map((item, k) => {
                          const point = Math.abs(
                            parseInt(item.total_points, 10),
                          );
                          return (
                            <Fragment key={`${i}${k}`}>
                              <tr>
                                <CustomTd>
                                  <Text>
                                    {format(
                                      item.transaction_date,
                                      "dd.MM.yyyy",
                                    )}
                                  </Text>
                                </CustomTd>
                                <DescDesktop width="50%">
                                  <Desc
                                    label={item.label}
                                    orderNr={item.orderNr}
                                  />
                                </DescDesktop>
                                <CustomTd align="right">
                                  <CapsContainer>
                                    <Caps>
                                      {parseInt(item.total_points, 10) >= 0 ? (
                                        <>
                                          {!!point && (
                                            <IconPlus10
                                              fill={colors.success}
                                              width={10}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {!!point && (
                                            <IconMinus10
                                              fill={colors.primaryDk}
                                              width={10}
                                            />
                                          )}
                                        </>
                                      )}
                                    </Caps>
                                    <div>
                                      <Crowns
                                        $count={
                                          parseInt(item.total_points, 10) >= 0
                                        }
                                      >
                                        {pointsToCouronnes(point)} couronnes
                                      </Crowns>
                                    </div>
                                  </CapsContainer>
                                  {shouldShowPoints(item.transaction_date) && (
                                    <Points>{point} points</Points>
                                  )}
                                </CustomTd>
                              </tr>
                              <DescMobile>
                                <td colSpan={2}>
                                  <Desc
                                    label={item.label}
                                    orderNr={item.orderNr}
                                  />
                                </td>
                              </DescMobile>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Order>
                </Month>
              </RowYear>
            ))}
          </Col>
        </Row>
        <ButtonMore {...buttonMoreProps} />
      </Wrapper>
    </Container>
  );
};
