import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useDimensions, useWindowSize } from 'react-recipes';
import styled from 'styled-components';

import { CustomH1 } from '@royalcanin-fr-loyalty/ui-kit/src/CustomH1';

import { Catalog } from '../components/Catalog';
import { Layout } from '../components/Layout';
import { useLocationContext } from '../lib/LocationProvider';
import { withPage } from '../lib/withPage';

const H1 = styled(CustomH1)`
  visibility: hidden;
`;

const CatalogLayout = () => {
  const [isBottom, setIsBottom] = useState(false);
  const [wrapperRef, dimensions] = useDimensions();
  const { height } = useWindowSize();

  const hasScroll = dimensions && dimensions.height > height;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Explorez le catalogue de cadeaux du 'Programme Ambassadeur' de Royal Canin. Découvrez les récompenses exclusives disponibles pour les éleveurs de chiens et chats en France et échangez vos couronnes pour les obtenir."
        />
      </Helmet>

      <Layout
        ref={wrapperRef}
        onVisibilitySensorChange={(isVisible) => {
          setIsBottom(isVisible);
        }}
      >
        <H1>Catalogue</H1>
        <Catalog
          isBottom={isBottom}
          hasScroll={hasScroll}
          filters={useLocationContext().query}
        />
      </Layout>
    </>
  );
};

export default withPage(CatalogLayout, {
  title: "Catalogue",
});
