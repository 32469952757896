import React from "react";
import styled, { css } from "styled-components";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

type ITheme = "success" | "warning" | "danger" | "blue";

type ISize = "xs";

const Container = styled.div<{ theme: ITheme; size?: ISize }>`
  ${fontFamily(ffdinPro.condensedBold)}
  ${fontSize("14px")}
  border-radius: 15px;
  color: #fff;
  height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  line-height: 1;
  box-sizing: border-box;
  display: inline-block;
  line-height: 2rem;
  text-align: center;
  white-space: nowrap;

  ${(p) =>
    p.theme === "success" &&
    css`
      background: ${colors.success};
    `}

  ${(p) =>
    p.theme === "danger" &&
    css`
      background: ${colors.danger};
    `}

  ${(p) =>
    p.theme === "warning" &&
    css`
      background: ${colors.warning};
    `}

  ${(p) =>
    p.theme === "blue" &&
    css`
      background: ${colors.blue};
    `}

  ${(p) =>
    p.size === "xs" &&
    css`
      min-width: 65px;
      height: 20px;
      border-radius: 10px;
      ${fontFamily(ffdinPro.bold)}
      text-transform: capitalize;
      ${fontSize("11px")}
      line-height: 1.313rem;
    `}
`;

export const Tag = ({
  children,
  theme,
  size,
}: {
  children: React.ReactNode;
  theme: ITheme;
  size?: ISize;
}) => (
  <Container theme={theme} size={size}>
    {children}
  </Container>
);
