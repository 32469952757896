import { Collapse } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { Checkbox, ICheckboxProps } from "./Checkbox";
import { IconArrowB10 } from "./icons-ts";
import { colors } from "./lib/constants";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

interface ICheckboxListItem {
  value?: string;
  label: string;
}

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const Text = styled.div`
  cursor: pointer;
`;

const Item = ({ label, ...props }: ICheckboxProps & { label: string }) => (
  <ItemContainer>
    <Checkbox {...props} />
    <Text
      onClick={(e: any) =>
        props.onChange && props.onChange(e, props.checked || true)
      }
    >
      {label}
    </Text>
  </ItemContainer>
);

const LinkMore = styled.div<{ checked: boolean }>`
  ${fontFamily(ffdinPro.bold)}
  ${fontSize("14px")}
  align-items: center;
  color: ${colors.primary};
  cursor: pointer;
  display: flex;
  transition: margin-top 0.2s;

  ${IconArrowB10} {
    flex-shrink: 0;
    margin-right: 15px;
    transition: transform 0.2s;

    &:hover {
      color: ${colors.primaryDk};
    }

    ${(p) =>
      p.checked &&
      css`
        transform: rotate(180deg);
      `}
  }
`;
export function CheckboxList({
  value,
  onChange,
  ...props
}: {
  value: {
    [key: string]: boolean;
  };
  onChange: (value: { [key: string]: boolean }) => void;
} & IAbstractCheckboxListProps) {
  return (
    <AbstractCheckboxList
      {...props}
      isChecked={(item) => (item.value && value[item.value]) || false}
      onChange={(item) => {
        if (item.value) {
          onChange({
            ...value,
            [item.value]: !value[item.value],
          });
        }
      }}
    />
  );
}

export function CheckboxListString({
  value,
  onChange,
  ...props
}: {
  value: string[];
  onChange: (value: string[]) => void;
} & IAbstractCheckboxListProps) {
  return (
    <AbstractCheckboxList
      {...props}
      isChecked={(item) =>
        (item.value && value.indexOf(item.value) >= 0) || false
      }
      onChange={(item) => {
        if (item.value) {
          const newValue =
            value.indexOf(item.value) >= 0
              ? value.filter((v) => v !== item.value)
              : [...value, item.value];
          onChange(newValue);
        }
      }}
    />
  );
}

interface IAbstractCheckboxListProps {
  items: ICheckboxListItem[];
  disabled?: boolean;
}

const ItemWrapper = styled.div`
  margin-bottom: 10px;
`;

const AbstractCheckboxList = ({
  items,
  isChecked,
  onChange,
  disabled,
}: {
  isChecked: (item: ICheckboxListItem) => boolean;
  onChange: (item: ICheckboxListItem) => void;
} & IAbstractCheckboxListProps) => {
  const [checked, setChecked] = React.useState(false);

  const showMore = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <ItemWrapper>
        {items.slice(0, 5).map((item, i) => (
          <Item
            value={item.value}
            checked={isChecked(item)}
            key={i}
            label={item.label}
            onChange={() => {
              onChange(item);
            }}
          />
        ))}
      </ItemWrapper>
      <Collapse in={checked}>
        {items.slice(5).map((item, i) => (
          <Item
            value={item.value}
            checked={isChecked(item)}
            key={i}
            label={item.label}
            onChange={() => {
              onChange(item);
            }}
          />
        ))}
      </Collapse>
      {items.length > 5 && (
        <CustomLinkMore checked={checked} onClick={showMore}>
          <IconArrowB10
            width={10}
            height={10}
            fill={colors.primary}
          ></IconArrowB10>
          voir plus
        </CustomLinkMore>
      )}
    </>
  );
};

const CustomLinkMore = styled(LinkMore)<{ checked?: boolean }>`
  margin-top: ${(p) => (p.checked ? "10px" : "0")};
`;
