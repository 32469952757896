import Tippy, { TippyProps } from "@tippy.js/react";
import React from "react";
import styled from "styled-components";
import { ffdinPro, fontFamily, fontSize } from "./lib/fonts";

const CustomTipped = styled(Tippy)`
  padding: 15px 10px;
  max-width: 230px !important;

  .tippy-content {
    padding: 0;
  }
`;

const Content = styled.div`
  ${fontSize("12px")}
  ${fontFamily(ffdinPro.regular)}
  line-height: 0.875rem;
`;

export const Tipped = ({
  children,
  content,
  ...props
}: {
  children: React.ReactNode;
  content: React.ReactNode;
} & TippyProps) => (
  <CustomTipped
    trigger="click"
    interactive
    placement="bottom-end"
    content={<Content>{content}</Content>}
    offset={"10, 3"}
    {...props}
  >
    <div>{children}</div>
  </CustomTipped>
);
