import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import Select, { SelectProps } from "@material-ui/core/Select";
import React from "react";
import styled from "styled-components";
import { IconArrowB16 } from "./icons-ts";
import { colors } from "./lib/constants";

export interface IDropdownItem<T> {
  value: T;
  label: string;
  items?: IDropdownItem<T>[];
}

const Arrow = styled(({ className }) => (
  <div className={className}>
    <IconArrowB16 width={16} fill={colors.darkDk} />
  </div>
))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Dropdown = <T extends any = any>({
  items,
  label,
  helperText,
  ...props
}: {
  items: IDropdownItem<T>[];
  label?: string | JSX.Element;
  helperText?: string;
} & SelectProps) => (
  <FormControl fullWidth margin="none">
    {label && typeof label === "string" ? (
      <InputLabel htmlFor={label}>{label}</InputLabel>
    ) : (
      label
    )}
    <Select IconComponent={Arrow} {...props}>
      {items.map((item, index) => {
        if (item.items) {
          if (!item.items.length) {
            return;
          }
          return [
            <ListSubheader key={`ListSubheader_${index}`}>{item.label}</ListSubheader>,
            ...item.items.map(i => (
              <MenuItem key={`listitem_${index}_${i.value}`} value={i.value as any}>
                {i.label}
              </MenuItem>
            )),
          ];
        }
        return (
          <MenuItem key={`items-${index}-${item.value}`} value={item.value as any}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
    {helperText && <FormHelperText error={true}>{helperText}</FormHelperText>}
  </FormControl>
);
