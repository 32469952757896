import React from 'react';
import Helmet from 'react-helmet';
import { Col, Row } from 'react-styled-flexboxgrid';

import { NetworkStatus } from 'apollo-client';
import { OrdersDetail, Wrapper } from '@royalcanin-fr-loyalty/ui-kit';

import { Layout } from '../components/Layout';
import { useCatalogPurchases } from '../lib/hooks/useCatalogPurchases';
import { isWebpSupported } from '../lib/utils';
import { withPage } from '../lib/withPage';

const Orders = () => {
  const { catalogPurchases, pageInfo, onLoadMore, loading, networkStatus } =
    useCatalogPurchases();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Consultez l'historique de vos commandes sur la plateforme 'Programme Ambassadeur' de Royal Canin. Retrouvez le récapitulatif des cadeaux commandés avec vos couronnes, spécialement dédiés aux éleveurs de chiens et chats en France."
        />
      </Helmet>
      <Layout>
        <Wrapper>
          <Row center="xs">
            <Col xs={12} sm={12} md={12} lg={8}>
              <OrdersDetail
                orders={catalogPurchases.map((catalogPurchase) => ({
                  ...catalogPurchase,
                  productData: {
                    ...catalogPurchase.productData,
                    image: isWebpSupported()
                      ? catalogPurchase.productData &&
                        catalogPurchase.productData.thumbImage?.webp
                      : catalogPurchase.productData &&
                        catalogPurchase.productData.thumbImage?.src,
                  },
                }))}
                onMoreClick={onLoadMore}
                moreLoading={networkStatus === NetworkStatus.fetchMore}
                pageInfo={pageInfo}
                loading={loading && catalogPurchases.length === 0}
              />
            </Col>
          </Row>
        </Wrapper>
      </Layout>
    </>
  );
};

export default withPage(Orders, {
  title: "Commandes",
});
